import { ILoadPaginatedForOrg, MAX_PAGE_SIZE } from "@smartrr/shared/utils/paginatedQuery";

export function getQueryObj(
  pageNumber: number,
  startDate?: string,
  endDate?: string,
  filterInContent?: { [key: string]: string[] },
  filterLikeContent?: { [key: string]: string },
  orderByField?: string
): ILoadPaginatedForOrg {
  return {
    queryParams: {
      pageSize: MAX_PAGE_SIZE,
      pageNumber,
      ...(startDate && { filterAfter: { createdDate: startDate } }),
      ...(endDate && { filterBefore: { createdDate: endDate } }),
      ...(filterInContent && { filterIn: filterInContent }),
      ...(filterLikeContent && { filterLike: filterLikeContent }),
      orderBy: {
        [orderByField ?? "id"]: "ASC",
      },
    },
  };
}
